import { Button, SvgIcon } from '@mui/material'
import EvmIcon from '@/public/images/evm.svg'
import useConnectWallet from '@/components/common/ConnectWallet/useConnectWallet'

import css from './styles.module.css'

const ConnectWalletButton = ({
  onConnect,
  contained = true,
  small = false,
  text,
  showIcon,
}: {
  onConnect?: () => void
  contained?: boolean
  small?: boolean
  text?: string
  showIcon?: boolean
}): React.ReactElement => {
  const connectWallet = useConnectWallet()

  const handleConnect = () => {
    onConnect?.()
    connectWallet()
  }

  return (
    <Button
      data-testid="connect-wallet-btn"
      onClick={handleConnect}
      variant={contained ? 'contained' : 'text'}
      size={small ? 'small' : 'medium'}
      disableElevation
      fullWidth
      sx={{
        fontSize: small ? ['13px', '14px'] : '14px',
        fontWeight: 400,
        gap: '8px',
        padding: ['12px 8px', '12px 24px'],
      }}
      color={contained ? 'primary' : 'inherit'}
    >
      {showIcon && (
        <SvgIcon
          component={EvmIcon}
          inheritViewBox
          fontSize="small"
          style={{ width: '24px', height: '24px' }}
          alt="EVM"
        />
      )}
      {Boolean(text) ? (
        text
      ) : (
        <>
          Connect <span className={css.hideMobile}>EVM Wallet</span>
        </>
      )}
    </Button>
  )
}

export default ConnectWalletButton
