import {
  BitgetConnector,
  OKXConnector,
  TokenPocketConnector,
  UnisatConnector,
  WizzConnector,
  XverseConnector,
} from './connector'
import { PARTICLE_CONFIG } from '@/config/constants'

export const aaOptions = {
  BTC: [
    {
      chainIds: [4200, 686868],
      version: '1.0.0',
    },
    {
      chainIds: [],
      version: '2.0.0',
    },
  ],
}

export type ContractName = keyof typeof aaOptions

export const defaultAccountContract = {
  name: Object.keys(aaOptions)?.[0] || 'BTC',
  // @ts-ignore
  version: aaOptions[Object.keys(aaOptions)?.[0] || 'BTC']?.[0].version || '1.0.0',
}

export const rpcUrls = {
  686868: 'https://testnet-rpc.merlinchain.io',
}

export const walletOptions = {
  visible: true,
}

export const SAContractKey = 'particle-sa-config'

export const particleConfig = PARTICLE_CONFIG

export const connectors = [
  new UnisatConnector(),
  new OKXConnector(),
  new BitgetConnector(),
  new TokenPocketConnector(),
  new WizzConnector(),
  new XverseConnector(),
]

// @ts-ignore
const chainIds = aaOptions[defaultAccountContract.name]
  ?.filter((item: any) => item.version === defaultAccountContract.version)
  .map((item: any) => item.chainIds)
  .reduce((a: any, b: any) => {
    a.push(...b)
    return a
  }, [])

export const evmSupportChainIds = Array.from(new Set(chainIds || [])) as number[]

export const autoConnect = true
