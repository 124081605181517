export enum EventName {
  sendUserOp = 'sendUserOp',
  sendUserOpResult = 'sendUserOpResult',

  personalSign = 'personalSign',
  personalSignResult = 'personalSignResult',

  signTypedData = 'signTypedData',
  signTypedDataResult = 'signTypedDataResult',

  ethProviderInit = 'ethProviderInit',
}
