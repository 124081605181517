import { useCallback, useState, type ReactElement } from 'react'
import { Box, Divider, Drawer } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'

import SidebarHeader from '@/components/sidebar/SidebarHeader'
import SidebarNavigation from '@/components/sidebar/SidebarNavigation'

import css from './styles.module.css'
import { trackEvent, OVERVIEW_EVENTS } from '@/services/analytics'
import MyAccounts from '@/components/welcome/MyAccounts'
import BatchSidebar from '@/components/batch/BatchSidebar'

const Sidebar = (): ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isBatchOpen, setBatchOpen] = useState<boolean>(false)

  const onDrawerToggle = useCallback(() => {
    setIsDrawerOpen((isOpen) => {
      trackEvent({ ...OVERVIEW_EVENTS.SIDEBAR, label: isOpen ? 'Close' : 'Open' })

      return !isOpen
    })
  }, [])

  const closeDrawer = useCallback(() => setIsDrawerOpen(false), [])

  return (
    <div data-testid="sidebar-container" className={css.container}>
      <div className={css.scroll}>
        <button data-testid="open-safes-icon" className={css.drawerButton} onClick={onDrawerToggle}>
          <ChevronRight />
        </button>

        <SidebarHeader />

        <Divider />

        <SidebarNavigation onBatchToggle={setBatchOpen} />

        <Box flex={1} />
      </div>

      <Drawer variant="temporary" anchor="left" open={isDrawerOpen} onClose={onDrawerToggle}>
        <div className={css.drawer}>
          <MyAccounts onLinkClick={closeDrawer} isDrawer></MyAccounts>
        </div>
      </Drawer>

      <BatchSidebar isOpen={isBatchOpen} onToggle={setBatchOpen} />
    </div>
  )
}

export default Sidebar
