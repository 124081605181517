import ExternalStore from '@/services/ExternalStore'

type SignModalStore = {
  isOpen: boolean
}

const defaultValue = {
  isOpen: false,
}

export const signModalStore = new ExternalStore<SignModalStore>(defaultValue)

export const openSignModal = () => {
  signModalStore.setStore({ isOpen: true })
}

export const closeSignModal = () => {
  signModalStore.setStore({ isOpen: false })
}
