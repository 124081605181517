import { type AccountContract, SmartAccount } from '@particle-network/aa'
import { AASignerProvider } from '../evmSigner'
import {
  aaOptions,
  autoConnect,
  connectors,
  defaultAccountContract,
  evmSupportChainIds,
  particleConfig,
  rpcUrls,
  SAContractKey,
  walletOptions,
} from '../config'
import { checkBTCVersion } from '../utils'
import { getBTCAAAddress, getBTCAccountInfo } from '../utils/ethereumUtils'
import type { BaseConnector } from '../connector'
import { chains } from '@particle-network/chains'
import { walletEntryPlugin } from '@particle-network/wallet'
import events from '../utils/eventUtils'
import txConfirm from '../utils/txConfirmUtils'
import { EventName } from '../types/eventName'
import { closeSignModal } from '../stores/signModalStore'
import { closeConnectModal } from '../stores/connectModalStore'
import type { EthereumProvider } from '../evmSigner/provider'
import { getEthProvider } from '../utils/providerUtils'

export class BtcConnectManager {
  private _connectorId?: string
  private _accounts?: string[]
  private _evmAccount?: string
  private _accountContract: AccountContract = defaultAccountContract
  private _ethProvider?: EthereumProvider

  get connectorId() {
    return this._connectorId
  }

  set connectorId(id: string | undefined) {
    const hasChanged = this._connectorId !== id
    this._connectorId = id

    if (hasChanged) {
      this._updateAccounts()
      this._updateConnectorListeners()
    }
  }

  get connector() {
    return connectors.find((item: any) => item.metadata.id === this._connectorId)
  }

  get accounts() {
    return this._accounts
  }

  set accounts(newAcc: string[] | undefined) {
    const hasChanged = this._accounts !== newAcc
    this._accounts = newAcc

    if (hasChanged) {
      this._updateEvmAccount()
      this._handleEvents()
    }
  }

  get accountContract() {
    return this._accountContract
  }

  set accountContract(config: AccountContract) {
    const hasChanged = this._accountContract !== config
    if (Object.keys(config).length === 0) {
      return
    }
    if (!checkBTCVersion(aaOptions, config.name, config.version)) {
      throw new Error('Invalid Account Contract')
    }
    if (window?.localStorage) {
      window?.localStorage.setItem(SAContractKey, JSON.stringify(config))
    }
    this._accountContract = config

    if (hasChanged) {
      this._updateEvmAccount()
      this._initWalletEntryPlugin()
      this._setWalletCore()
      this._toggleWalletEntry()
      this._initEthProvider()
    }
  }

  get evmAccount() {
    return this._evmAccount
  }

  set evmAccount(newAcc: string | undefined) {
    const hasChanged = this._evmAccount !== newAcc
    this._evmAccount = newAcc

    if (hasChanged) {
      this._toggleWalletEntry()
      this._initEthProvider()
    }
  }

  get ethProvider() {
    return this._ethProvider
  }

  getPublicKey = async () => {
    if (!this.connector) {
      throw new Error('Wallet not connected!')
    }
    return this.connector.getPublicKey()
  }

  signMessage = async (message: string) => {
    if (!this.connector) {
      throw new Error('Wallet not connected!')
    }
    return this.connector.signMessage(message)
  }

  sendBitcoin = async (toAddress: string, satoshis: number, options?: { feeRate: number }) => {
    if (!this.connector) {
      throw new Error('Wallet not connected!')
    }

    return this.connector.sendBitcoin(toAddress, satoshis, options)
  }

  getNetwork = async () => {
    if (!this.connector) {
      throw new Error('Wallet not connected!')
    }
    return this.connector.getNetwork()
  }

  switchNetwork = async (network: 'livenet' | 'testnet') => {
    if (!this.connector) {
      throw new Error('Wallet not connected!')
    }
    await this.connector.switchNetwork(network)
  }

  getSmartAccountInfo = async () => {
    if (this.accounts && this.accounts.length > 0 && this.smartAccount) {
      const accountInfo = await getBTCAccountInfo(
        this.smartAccount,
        this.accounts[0],
        this.accountContract.name,
        this.accountContract.version,
      )
      this.evmAccount = accountInfo.smartAccountAddress
      return accountInfo
    }
    return undefined
  }

  requestAccount = async (connector: BaseConnector) => {
    let accounts = await this.connector?.getAccounts()
    if (accounts?.length === 0 && autoConnect) {
      accounts = await connector.requestAccounts()
    }
    this.accounts = accounts
  }

  disconnect = () => {
    window?.localStorage.removeItem('current-connector-id')
    txConfirm.reset()
    if (this.connector) {
      this.connector.disconnect()
    }
    this.connectorId = undefined
    this._ethProvider = undefined
  }

  constructor() {
    this._setAccountContractOnLoad()
    this._setConnectorOnLoad()

    // not sure
    this._updateEvmAccount()
    this._updateAccounts()
    // this._updateConnectorListeners();
    this._initWalletEntryPlugin()
    this._setWalletCore()
    this._toggleWalletEntry()
  }

  get smartAccount() {
    if (typeof window === 'undefined') {
      return undefined
    }

    if (
      !(window as any).__bitcoinSmartAccount ||
      ((window as any)?.__bitcoinSmartAccount &&
        ((window as any)?.__bitcoinSmartAccount.smartAccountContract.version !== this._accountContract.version ||
          (window as any)?.__bitcoinSmartAccount.smartAccountContract.name !== this._accountContract.name))
    ) {
      const smartAccount = new SmartAccount(
        new AASignerProvider(evmSupportChainIds, particleConfig.projectId, particleConfig.clientKey, rpcUrls) as any,
        { ...particleConfig, aaOptions: { accountContracts: aaOptions } },
      )
      smartAccount.setSmartAccountContract(this._accountContract)
      ;(window as any).__bitcoinSmartAccount = smartAccount
    }
    ;(window as any).__bitcoinSmartAccount.provider.getPublicKey = this.getPublicKey
    ;(window as any).__bitcoinSmartAccount.provider.personalSign = this.signMessage

    return (window as any).__bitcoinSmartAccount as SmartAccount
  }

  get provider() {
    if (this.connectorId) {
      return connectors.find((item: any) => item.metadata.id === this.connectorId)?.getProvider()
    }
  }

  // rewritten hooks
  private _updateEvmAccount() {
    // [accountContract, accounts, smartAccount]
    const smartAccount = this.smartAccount
    if (this.accounts && this.accounts.length > 0 && smartAccount) {
      getBTCAAAddress(smartAccount, this.accounts[0], this.accountContract.name, this.accountContract.version)
        .then((res: any) => {
          this.evmAccount = res
        })
        .catch((e: any) => {
          this.evmAccount = undefined
          console.error('smartAccount getAddress error', e)
        })
    } else {
      this.evmAccount = undefined
    }
  }

  private _updateAccounts() {
    // [connector, requestAccount]
    if (this.connector) {
      this.requestAccount(this.connector).catch((e: any) => {
        console.log('get account error', e)
        this.accounts = []
      })
    } else {
      this.accounts = []
    }
  }

  private _updateConnectorListeners = () => {
    // [connector]
    // @ts-ignore
    this.connector?.on('accountsChanged', (accounts: string[]) => (this.accounts = accounts))
  }

  private _initWalletEntryPlugin = () => {
    // [options, evmSupportChainIds, accountContract]
    if (walletOptions?.visible !== false) {
      const supportChains = evmSupportChainIds.map((id: any) => chains.getEVMChainInfoById(id))
      if (supportChains.some((chain: any) => !chain)) {
        throw new Error(`Please config valid chain ids, ${JSON.stringify(evmSupportChainIds)}`)
      }
      walletEntryPlugin.init(particleConfig, {
        ...walletOptions,
        erc4337: this.accountContract,
        customStyle: {
          supportChains: supportChains as any,
        },
      })
    }
  }

  private _setWalletCore = () => {
    // [smartAccount, options]
    const smartAccount = this.smartAccount
    if (smartAccount && walletOptions?.visible !== false) {
      walletEntryPlugin.setWalletCore({
        ethereum: smartAccount.provider,
      })
    }
  }

  private _toggleWalletEntry = () => {
    // [evmAccount, smartAccount, options]
    if (walletOptions?.visible !== false) {
      if (this.evmAccount) {
        walletEntryPlugin.walletEntryCreate()
      } else {
        walletEntryPlugin.walletEntryDestroy()
      }
    }
  }

  private _handleEvents = () => {
    // [accounts, closeConnectModal, closeSignModal]
    if (!this.accounts || this.accounts.length === 0) {
      closeConnectModal()
      closeSignModal()
      if (events.listenerCount(EventName.sendUserOpResult) > 0) {
        events.emit(EventName.sendUserOpResult, {
          error: {
            code: -32600,
            message: 'Wallet disconnected',
          },
        })
      } else if (events.listenerCount(EventName.personalSignResult) > 0) {
        events.emit(EventName.personalSignResult, {
          error: {
            code: -32600,
            message: 'Wallet disconnected',
          },
        })
      } else if (events.listenerCount(EventName.signTypedDataResult) > 0) {
        events.emit(EventName.signTypedDataResult, {
          error: {
            code: -32600,
            message: 'Wallet disconnected',
          },
        })
      }
    }
  }

  // init methods
  private _setAccountContractOnLoad = () => {
    if (typeof window !== 'undefined' && window?.localStorage) {
      this.accountContract = JSON.parse(window?.localStorage.getItem(SAContractKey) || '{}')
    }
  }

  private _setConnectorOnLoad = () => {
    if (typeof window !== 'undefined' && window?.localStorage) {
      const id = window?.localStorage.getItem('current-connector-id')
      if (autoConnect && id) {
        this.connectorId = id
      }
    }
  }

  private _initEthProvider = () => {
    if (!this._ethProvider && this._evmAccount && this.smartAccount?.provider) {
      this._ethProvider = getEthProvider(this)
      events.emit(EventName.ethProviderInit)
    }
  }
}
