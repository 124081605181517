import { type WalletMetadata } from './base'
import { InjectedConnector } from './injected'

export class WizzConnector extends InjectedConnector {
  readonly metadata: WalletMetadata = {
    id: 'wizz',
    name: 'Wizz Wallet',
    icon: '/images/btc-connect/wizz.svg',
    downloadUrl: 'https://wizzwallet.io',
  }

  constructor() {
    super('wizz')
  }
}
