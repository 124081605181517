import type { ReactElement } from 'react'
import { useRouter } from 'next/router'

import css from './styles.module.css'

const Footer = (): ReactElement | null => {
  const router = useRouter()

  return <footer className={css.container}></footer>
}

export default Footer
