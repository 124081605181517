import ExternalStore from '@/services/ExternalStore'

type ConnectModalStore = {
  isOpen: boolean
}

const defaultValue = {
  isOpen: false,
}

export const connectModalStore = new ExternalStore<ConnectModalStore>(defaultValue)

export const openConnectModal = () => {
  connectModalStore.setStore({ isOpen: true })
}

export const closeConnectModal = () => {
  connectModalStore.setStore({ isOpen: false })
}
