const darkPalette = {
  text: {
    primary: '#FAFAFA',
    secondary: '#636669',
    disabled: '#636669',
  },
  primary: {
    dark: '#1176ca',
    main: '#3193E4',
    light: '#ABABAB',
    background: '#3193E408',
  },
  secondary: {
    dark: '#636669',
    main: '#FFFFFF',
    light: '#B0FFC9',
    background: '#1B2A22',
  },
  border: {
    main: '#636669',
    light: '#303033',
    background: '#101011',
    dark: '#F0F0F0',
  },
  error: {
    dark: '#AC2C3B',
    main: '#E44E45',
    light: '#FFB4BD',
    background: '#E44E451A',
  },
  success: {
    dark: '#1176ca',
    main: '#71DD5F',
    light: '#4b9bdd',
    background: '#71DD5F1A',
  },
  info: {
    dark: '#52BFDC',
    main: '#FDAC5D',
    light: '#B7F0FF',
    background: '#FDAC5D1A',
  },
  warning: {
    dark: '#C04C32',
    main: '#FF8061',
    light: '#FFBC9F',
    background: '#FF80610A',
  },
  background: {
    default: '#101011',
    main: '#101011',
    paper: '#171819',
    light: '#1B2A22',
  },
  backdrop: {
    main: '#000',
  },
  logo: {
    main: '#FFFFFF',
    background: '#303033',
  },
  static: {
    main: '#101011',
    dark: '#FFFFFF1A',
    background: '#3193E41A',
  },
}

export default darkPalette
