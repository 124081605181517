import { type WalletMetadata } from './base'
import { InjectedConnector } from './injected'

export class UnisatConnector extends InjectedConnector {
  readonly metadata: WalletMetadata = {
    id: 'unisat',
    name: 'Unisat Wallet',
    icon: '/images/btc-connect/unisat.svg',
    downloadUrl: 'https://unisat.io',
  }

  constructor() {
    super('unisat')
  }
}
