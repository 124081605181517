import type { ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import { createEIP1193Provider, type WalletInit } from '@web3-onboard/common'
import { getRpcServiceUrl } from '@/hooks/wallets/web3'
import { openConnectModal } from '@/features/btc-connect/stores/connectModalStore'
import { btcConnectManagerStore } from '@/features/btc-connect/stores/btcConnectManagerStore'
import events from '@/features/btc-connect/utils/eventUtils'
import { EventName } from '@/features/btc-connect/types/eventName'

export const BTC_CONNECT_MODULE_LABEL = 'BTC Connect'

let currentChainId = ''
let currentRpcUri = ''
const BtcConnectModule = (chainId: ChainInfo['chainId'], rpcUri: ChainInfo['rpcUri']): WalletInit => {
  currentChainId = chainId
  currentRpcUri = getRpcServiceUrl(rpcUri)

  return () => {
    return {
      label: BTC_CONNECT_MODULE_LABEL,
      getIcon: async () => (await import('./icon')).default,
      getInterface: async () => {
        const evmAccount = btcConnectManagerStore.getStore()?.evmAccount
        let provider = btcConnectManagerStore.getStore()?.ethProvider
        // no need to open the modal if already connected
        if (!evmAccount) {
          openConnectModal()
          provider = await new Promise<any>((resolve, reject) => {
            events.on(EventName.ethProviderInit, () => resolve(btcConnectManagerStore.getStore()?.ethProvider))
          })
        }

        return {
          provider: createEIP1193Provider(provider),
        }
      },
      platforms: ['desktop'],
    }
  }
}

export default BtcConnectModule
