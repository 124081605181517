import { useEffect, useState } from 'react'
import { type BaseConnector } from '../../connector/base'
import Button from '../button'
import Modal from '../modal'
import styles from './connect.module.scss'
import { closeConnectModal, connectModalStore } from '../../stores/connectModalStore'
import { connectors } from '../../config'
import { btcConnectManagerStore } from '../../stores/btcConnectManagerStore'

const ConnectModal = () => {
  const open = connectModalStore.useStore()?.isOpen
  const btcConnectManager = btcConnectManagerStore.useStore()
  const [backVisible, setBackVisible] = useState(false)
  const [retryVisible, setRetryVisible] = useState(false)
  const [walletReady, setWalletReady] = useState(true)
  const [selectConnector, setSelectConnector] = useState<BaseConnector>()

  useEffect(() => {
    if (!open) {
      setBackVisible(false)
      setRetryVisible(false)
      setWalletReady(true)
      setSelectConnector(undefined)
    }
  }, [open])

  const connect = async (connectorId: string) => {
    const connector = connectors.find((item) => item.metadata.id === connectorId)
    if (!connector) {
      throw new Error(`connector id ${connectorId} not found`)
    }
    const accounts = await connector.requestAccounts()
    if (accounts.length > 0) {
      localStorage.setItem('current-connector-id', connector.metadata.id)
      if (btcConnectManager) {
        btcConnectManager.connectorId = connector.metadata.id
      }
    }
  }

  const onConnect = async (connector: BaseConnector) => {
    setBackVisible(true)
    setSelectConnector(connector)
    if (connector.isReady()) {
      try {
        await connect(connector.metadata.id)
        closeConnectModal()
      } catch (error: any) {
        console.error('onConnect error', error)
        if (error.code === 4001) {
          setRetryVisible(true)
        }
      }
    } else {
      setWalletReady(false)
    }
  }

  const onClose = () => {
    closeConnectModal()
  }

  const onBack = () => {
    setBackVisible(false)
    setRetryVisible(false)
    setWalletReady(true)
    setSelectConnector(undefined)
  }

  const onRetry = () => {
    setRetryVisible(false)
    if (selectConnector) {
      onConnect(selectConnector)
    }
  }

  return (
    <Modal open={!!open} onClose={onClose} isDismissable={false} contentClassName={styles.connectModal}>
      <div className={styles.title}>{selectConnector?.metadata.name || 'Choose Wallet'}</div>
      <img className={styles.closeBtn} src="/images/btc-connect/close.svg" onClick={onClose}></img>
      {backVisible && <img className={styles.backBtn} src="/images/btc-connect/back.svg" onClick={onBack}></img>}

      {!backVisible &&
        connectors.map((connector: any) => {
          return (
            <div className={styles.walletItem} key={connector.metadata.id} onClick={() => onConnect(connector)}>
              <img className={styles.walletIcon} src={connector.metadata.icon}></img>
              <div className={styles.walletName}>{connector.metadata.name}</div>
            </div>
          )
        })}

      {backVisible && selectConnector && (
        <div className={styles.connecting}>
          <div className={styles.connectingIconContainer}>
            <img className={styles.connectingIcon} src={selectConnector.metadata.icon}></img>
            {retryVisible && (
              <div className={styles.retryContainer} onClick={onRetry}>
                <img className={styles.retryIcon} src="/images/btc-connect/retry.svg"></img>
              </div>
            )}
          </div>

          {walletReady ? (
            <>
              <div className={styles.connection}>{retryVisible ? 'Request Cancelled' : 'Requesting Connection'}</div>
              <div className={styles.acceptRequest}>
                {retryVisible
                  ? 'You cancelled the request.\nClick above to try again.'
                  : 'Accept the request through your wallet to connect to this app.'}
              </div>
            </>
          ) : (
            <>
              <div className={styles.connection}>Wallet Not Installed.</div>
              <Button
                className={styles.btnDownload}
                onClick={() => {
                  window.open(selectConnector?.metadata.downloadUrl, '_blank')
                }}
              >
                Get
              </Button>
            </>
          )}
        </div>
      )}
    </Modal>
  )
}

export default ConnectModal
