import events, { getPendingSignEventAccount } from '@/features/btc-connect/utils/eventUtils'
import type { SendTransactionParams, UserOpBundle, UserOpParams } from '@particle-network/aa'
import type { BtcConnectManager } from '@/features/btc-connect/manager/BtcConnectManager'
import txConfirm from '@/features/btc-connect/utils/txConfirmUtils'
import { EventName } from '@/features/btc-connect/types/eventName'
import { EthereumProvider } from '@/features/btc-connect/evmSigner/provider'

export const getEthProvider = (connectManager?: BtcConnectManager) => {
  if (!connectManager) return
  const { smartAccount, evmAccount } = connectManager
  const buildUserOp = async ({ tx, feeQuote, tokenPaymasterAddress }: UserOpParams) => {
    if (!smartAccount) {
      throw new Error('The smart account is not initialized.')
    }
    const result = await smartAccount.buildUserOperation({ tx, feeQuote, tokenPaymasterAddress })
    return result
  }

  const sendUserOp = async (params: SendTransactionParams, forceHideConfirmModal?: boolean) => {
    if (!smartAccount) {
      throw new Error('The smart account is not initialized.')
    }

    const showConfirmModal = !forceHideConfirmModal && !txConfirm.isNotRemind()

    if (showConfirmModal) {
      if (getPendingSignEventAccount() > 0) {
        throw new Error('Operation failed, there is a transaction being processed')
      }
    }

    let userOpBundle: UserOpBundle | undefined
    if (
      Object.prototype.hasOwnProperty.call(params, 'userOpHash') &&
      Object.prototype.hasOwnProperty.call(params, 'userOp')
    ) {
      const { userOpHash, userOp } = params as UserOpBundle
      if (userOpHash && userOp) {
        userOpBundle = { userOpHash, userOp }
      }
    }

    if (!userOpBundle) {
      const { tx, feeQuote, tokenPaymasterAddress } = params as UserOpParams
      userOpBundle = await buildUserOp({ tx, feeQuote, tokenPaymasterAddress })
    }

    if (!showConfirmModal) {
      return smartAccount.sendUserOperation(userOpBundle)
    }

    return new Promise<string>((resolve, reject) => {
      events.emit(EventName.sendUserOp, userOpBundle)
      events.once(EventName.sendUserOpResult, ({ result, error }) => {
        if (result) {
          resolve(result)
        } else {
          reject(error)
        }
      })
    })
  }

  return new EthereumProvider(sendUserOp, smartAccount?.provider as any, evmAccount)
}
