import type { ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import { RPC_AUTHENTICATION } from '@safe-global/safe-gateway-typescript-sdk/dist/types/chains'

// @ts-ignore
export const MERLIN_TESTNET: ChainInfo = {
  chainId: '686868',
  chainName: 'Merlin Testnet',
  isTestnet: true,
  nativeCurrency: {
    name: 'Bitcoin',
    symbol: 'BTC',
    decimals: 18,
    logoUri: 'http://prod-safe-infrastructure.s3.us-east-2.amazonaws.com/chains/686868/currency_logo.png',
  },
  transactionService: 'https://merlin-testnet-api.asigna.io/txs',
  blockExplorerUriTemplate: {
    address: 'https://testnet-scan.merlinchain.io/address/{{address}}',
    txHash: 'https://testnet-scan.merlinchain.io/tx/{{txHash}}',
    api: 'https://testnet-scan.merlinchain.io/api/?module={{module}}&action={{action}}&address={{address}}&tag=latest&api_key={{apiKey}}',
  },
  disabledWallets: [],
  ensRegistryAddress: null,
  // @ts-ignore
  features: ['EIP1271', 'ERC20', 'ERC721', 'SAFE_APPS'],
  gasPrice: [],
  publicRpcUri: {
    authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
    value: 'https://testnet-rpc.merlinchain.io',
  },
  rpcUri: {
    authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
    value: 'https://testnet-rpc.merlinchain.io',
  },
  safeAppsRpcUri: {
    authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
    value: 'https://testnet-rpc.merlinchain.io',
  },
  shortName: 'Merlin-Testnet',
  theme: {
    textColor: '#ffffff',
    backgroundColor: '#000000',
  },
  balancesProvider: {
    chainName: 'Bitcoin',
    enabled: true,
  },
}

// @ts-ignore
export const MERLIN_MAINNET: ChainInfo = {
  chainId: '4200',
  chainName: 'Merlin Mainnet',
  description: 'Merlin is good',
  chainLogoUri: 'http://prod-safe-infrastructure.s3.us-east-2.amazonaws.com/chains/4200/chain_logo.png',
  l2: true,
  isTestnet: false,
  nativeCurrency: {
    name: 'Bitcoin',
    symbol: 'BTC',
    decimals: 18,
    logoUri: 'http://prod-safe-infrastructure.s3.us-east-2.amazonaws.com/chains/4200/currency_logo.png',
  },
  transactionService: 'http://nginx:8000/txs',
  blockExplorerUriTemplate: {
    address: 'https://scan.merlinchain.io/address/{{address}}',
    txHash: 'https://scan.merlinchain.io/tx/{{txHash}}',
    api: 'https://scan.merlinchain.io/api/?module={{module}}&action={{action}}&address={{address}}&tag=latest&api_key={{apiKey}}',
  },
  disabledWallets: [],
  ensRegistryAddress: null,
  // @ts-ignore
  features: ['EIP1271', 'ERC20', 'ERC721', 'SAFE_APPS'],
  gasPrice: [],
  publicRpcUri: {
    authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
    value: 'https://rpc.merlinchain.io',
  },
  rpcUri: {
    authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
    value: 'https://rpc.merlinchain.io',
  },
  safeAppsRpcUri: {
    authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
    value: 'https://rpc.merlinchain.io',
  },
  shortName: 'Merlin-Mainnet',
  theme: {
    textColor: '#ffffff',
    backgroundColor: '#000000',
  },
  balancesProvider: {
    chainName: 'Bitcoin',
    enabled: true,
  },
}
