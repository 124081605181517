import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import type { ReactElement, SyntheticEvent } from 'react'
import ModalDialog from '@/components/common/ModalDialog'
import Track from '@/components/common/Track'
import { OVERVIEW_EVENTS } from '@/services/analytics'
import DeleteIcon from '@/public/images/common/trash.svg'
import SvgIcon from '@mui/material/SvgIcon'
import { Box } from '@mui/material'

const BatchRemoveTxDialog = ({
  handleClose,
  handleConfirm,
}: {
  handleClose: () => void
  handleConfirm: (e: SyntheticEvent) => void
}): ReactElement => {
  return (
    <ModalDialog open onClose={handleClose}>
      <DialogContent sx={{ pt: '24px !important', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          sx={{
            width: '56px',
            height: '56px',
            border: '1px solid var(--color-error)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '56px',
            marginBottom: '24px',
          }}
        >
          <SvgIcon component={DeleteIcon} inheritViewBox color="error" fontSize="medium" />
        </Box>
        <Typography variant="h5" fontWeight={500} sx={{ textAlign: 'center', maxWidth: '380px' }}>
          Are you sure you want to permanently delete transaction from batch transactions?
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 0,
        }}
      >
        <Button
          data-testid="cancel-btn"
          onClick={handleClose}
          variant="contained"
          color="inherit"
          sx={{ minWidth: 132 }}
        >
          Cancel
        </Button>
        <Track {...OVERVIEW_EVENTS.DELETED_FROM_WATCHLIST}>
          <Button
            data-testid="delete-btn"
            onClick={handleConfirm}
            variant="contained"
            disableElevation
            sx={{ minWidth: 132 }}
          >
            Yes, Delete
          </Button>
        </Track>
      </DialogActions>
    </ModalDialog>
  )
}

export default BatchRemoveTxDialog
