import { type SyntheticEvent, useMemo, useCallback, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, ButtonBase, ListItem, SvgIcon } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import css from './styles.module.css'
import { type DraftBatchItem } from '@/store/batchSlice'
import TxType from '@/components/transactions/TxType'
import TxInfo from '@/components/transactions/TxInfo'
import DeleteIcon from '@/public/images/common/trash16.svg'
import TxData from '@/components/transactions/TxDetails/TxData'
import { MethodDetails } from '@/components/transactions/TxDetails/TxData/DecodedData/MethodDetails'
import { TxDataRow } from '@/components/transactions/TxDetails/Summary/TxDataRow'
import { dateString } from '@/utils/formatters'
import { BATCH_EVENTS, trackEvent } from '@/services/analytics'
import BatchRemoveTxDialog from '@/components/transactions/BatchRemoveTxDialog'

type BatchTxItemProps = DraftBatchItem & {
  id: string
  count: number
  onDelete?: (id: string) => void
}

const BatchTxItem = ({ id, count, timestamp, txDetails, onDelete }: BatchTxItemProps) => {
  const [open, setOpen] = useState(false)

  const txSummary = useMemo(
    () => ({
      timestamp,
      id: txDetails.txId,
      txInfo: txDetails.txInfo,
      txStatus: txDetails.txStatus,
      safeAppInfo: txDetails.safeAppInfo,
      txHash: txDetails.txHash || null,
    }),
    [timestamp, txDetails],
  )

  const handleDelete = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      onDelete?.(id)
      trackEvent(BATCH_EVENTS.BATCH_DELETE_TX)
    },
    [onDelete, id],
  )

  const handleExpand = () => {
    trackEvent(BATCH_EVENTS.BATCH_EXPAND_TX)
  }

  return (
    <>
      <ListItem disablePadding sx={{ gap: 1, alignItems: 'center' }}>
        <div className={css.number}>{count}</div>
        <Accordion elevation={0} sx={{ flex: 1 }} onChange={handleExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={css.accordion}>
            <Box flex={1} display="flex" alignItems="center" gap={2} py={0.4} width="100%">
              <TxType tx={txSummary} />

              <Box flex={1}>
                <TxInfo info={txDetails.txInfo} />
              </Box>

              {onDelete && (
                <ButtonBase onClick={() => setOpen(true)} title="Delete transaction" sx={{ p: 0.5, mr: [0.5, 1] }}>
                  <SvgIcon component={DeleteIcon} inheritViewBox fontSize="small" />
                </ButtonBase>
              )}
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <div className={css.details}>
              <TxData txDetails={txDetails} trusted imitation={false} />

              <TxDataRow title="Created:">{timestamp ? dateString(timestamp) : null}</TxDataRow>

              {txDetails.txData?.dataDecoded && (
                <MethodDetails
                  data={txDetails.txData.dataDecoded}
                  addressInfoIndex={txDetails.txData.addressInfoIndex}
                />
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </ListItem>
      {open && <BatchRemoveTxDialog handleClose={() => setOpen(false)} handleConfirm={handleDelete} />}
    </>
  )
}

export default BatchTxItem
